import AWS from "aws-sdk";

const bucketName = "ddjpictures";
let continuationAvailableToken = null;
let continuationSoldToken = null;
let allAvailablePicturesLoaded = false;
let allSoldPicturesLoaded = false;
let newPictureUploaded = "";

// Configure AWS SDK with your credentials
function setUpAWS() {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "eu-central-1",
  });
}

const MAX_TIMESTAMP = 9999999999999;
function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export async function uploadImage(
  selectedFile,
  title,
  naslovSlike,
  dimX,
  dimY
) {
  setUpAWS();
  // Specify the bucket name and folder/key where you want to store the image
  const s3 = new AWS.S3();
  const folder = "available/";
  const reverse_timestamp = MAX_TIMESTAMP - Date.now();
  const newFileName = `${reverse_timestamp}.${getFileExtension(
    selectedFile.name
  )}`; // Use the current timestamp as the filename
  newPictureUploaded = folder + newFileName;

  // Set the parameters for the S3 upload
  const params = {
    Bucket: bucketName,
    Key: newPictureUploaded,
    Body: selectedFile,
    Metadata: {
      title: title,
      titlesrb: naslovSlike,
      x_dim: dimX,
      y_dim: dimY,
      sold: "false",
    },
  };

  // Perform the S3 upload
  try {
    await s3.upload(params).promise();
    console.log("Image uploaded successfully:");
  } catch (error) {
    console.log("Error uploading image:", error);
  }
}

export async function fetchAndDisplayAvailableImages(fromBeginning) {
  try {
    const { images, continuationToken: newContinuationToken } =
      await fetchImagesWithPaging(
        "available",
        continuationAvailableToken,
        fromBeginning
      );
    if (newContinuationToken) {
      continuationAvailableToken = newContinuationToken;
      // Show "Load More" button or trigger next fetch when needed
    } else {
      console.log("All images fetched");
      allAvailablePicturesLoaded = true;
      return [images, allAvailablePicturesLoaded];
    }
    return [images, allAvailablePicturesLoaded];
  } catch (error) {
    console.log("Error:", error);
  }
}

export async function fetchAndDisplaySoldImages(fromBeginning) {
  try {
    const { images, continuationToken: newContinuationToken } =
      await fetchImagesWithPaging("sold", continuationSoldToken, fromBeginning);
    if (newContinuationToken) {
      continuationSoldToken = newContinuationToken;
      // Show "Load More" button or trigger next fetch when needed
    } else {
      console.log("All images fetched");
      allSoldPicturesLoaded = true;
      return [images, allSoldPicturesLoaded];
    }
    return [images, allSoldPicturesLoaded];
  } catch (error) {
    console.log("Error:", error);
  }
}

export async function fetchImagesWithPaging(
  folder,
  continuationToken,
  fromBeginning
) {
  setUpAWS();
  try {
    const s3 = new AWS.S3();
    if (fromBeginning) {
      continuationToken = null;
    }
    const listParams = {
      Bucket: bucketName,
      Prefix: folder + "/",
      MaxKeys: 25, // Set the maximum number of objects to retrieve (adjust as needed)
      ContinuationToken: continuationToken,
    };

    const response = await s3.listObjectsV2(listParams).promise();

    // const sortedResponse = response.Contents.sort(
    //   (a, b) => b.LastModified - a.LastModified
    // );
    const imageKeys = response.Contents.map((object) => object.Key);
    // Generate URLs for each image using the S3 bucket URL

    // Fetch metadata for each image
    const imagePromises = imageKeys.map(async (key) => {
      const headResponse = await s3
        .headObject({ Bucket: bucketName, Key: key })
        .promise();
      return {
        url: `https://${bucketName}.s3.amazonaws.com/${key}`,
        metadata: headResponse.Metadata,
      };
    });
    const imagesWithMeta = await Promise.all(imagePromises);
    return {
      images: imagesWithMeta,
      continuationToken: response.NextContinuationToken,
    };
  } catch (error) {
    console.log("Error fetching images:", error);
    return [];
  }
}

export async function fetchAndDisplayImages(folder, fromBegging) {
  if (folder === "available") {
    return fetchAndDisplayAvailableImages(fromBegging);
  } else {
    return fetchAndDisplaySoldImages(fromBegging);
  }
}

export async function handleUpdateMataData(objectKey, newMetadata) {
  const key = objectKey.split(".com/")[1];
  const s3 = new AWS.S3();
  const copyParams = {
    Bucket: bucketName,
    CopySource: `${bucketName}/${key}`, // Source object
    Key: key, // Destination object (same as the source, effectively updating the object)
    Metadata: newMetadata,
    MetadataDirective: "REPLACE", // This directive replaces the existing metadata with the new metadata
  };

  // Perform the "Copy Object" operation to update the metadata
  try {
    await s3.copyObject(copyParams).promise();
    console.log("Metadata updated successfully");
  } catch (error) {
    console.log("Error updating metadata:", error);
  }
}

export async function moveToDirectory(objectKey, sold) {
  const sourceFolder = sold ? "available/" : "sold";
  const destinationFolder = sold ? "sold/" : "available/";
  // Extract the key from the objectKey (remove the ".com/" prefix)
  const key = objectKey.split(".com/")[1].split("/")[1];
  const key2 = objectKey.split(".com/")[1];

  const s3 = new AWS.S3();

  // Copy the object to the destination folder
  const copyParams = {
    Bucket: bucketName,
    CopySource: `${bucketName}/${key2}`,
    Key: `${destinationFolder}${key}`, // Specify the destination folder in the key
    MetadataDirective: "COPY",
  };

  // Perform the "Copy Object" operation
  try {
    await s3.copyObject(copyParams).promise();
    console.log("Object copied successfully");
    const deleteParams = {
      Bucket: bucketName,
      Key: `${sourceFolder}${key}`,
    };

    try {
      await s3.deleteObject(deleteParams).promise();
      console.log("Original object deleted successfully");
    } catch (deleteErr) {
      console.log("Error deleting original object:", deleteErr);
    }
  } catch (error) {
    console.log("Error copying object:", error);
  }
}

export async function getPictureWithUpdatedMetadata(objectKey) {
  setUpAWS();
  try {
    const s3 = new AWS.S3();

    const headResponse = await s3
      .headObject({ Bucket: bucketName, Key: objectKey })
      .promise();

    const imageUrl = `https://${bucketName}.s3.amazonaws.com/${objectKey}`;
    const imageMetadata = headResponse.Metadata;

    return {
      url: imageUrl,
      metadata: imageMetadata,
    };
  } catch (error) {
    console.log("Error fetching image:", error);
    return null;
  }
}

export async function fetchSingleImage() {
  if (newPictureUploaded === "") {
    return null;
  }
  setUpAWS();
  try {
    const s3 = new AWS.S3();

    const headResponse = await s3
      .headObject({ Bucket: bucketName, Key: newPictureUploaded })
      .promise();

    const imageUrl = `https://${bucketName}.s3.amazonaws.com/${newPictureUploaded}`;
    const imageMetadata = headResponse.Metadata;

    return {
      url: imageUrl,
      metadata: imageMetadata,
    };
  } catch (error) {
    console.log("Error fetching image:", error);
    return null;
  }
}

export async function deleteImage(image) {
  setUpAWS();
  const s3 = new AWS.S3();
  const metadata = image.metadata;
  const newMetadata = {
    ...metadata,
    deleted: "true",
  };

  await handleUpdateMataData(image.url, newMetadata);
}

export async function reserveImage(image, metadata) {
  setUpAWS();
  const s3 = new AWS.S3();
  let reserved;
  if ("reserved" in metadata) {
    reserved = metadata["reserved"] === "true" ? "false" : "true";
  } else {
    reserved = "true";
  }
  const newMetadata = {
    ...metadata,
    reserved: reserved,
  };

  await handleUpdateMataData(image, newMetadata);
  return reserved;
}
